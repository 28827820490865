.search-input {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    width: 200px;
  }
  
.search-icon {
    background-color: #ccc;
    border: none;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    color:#fff;
  }

.form{
    position: relative;
}

.form .fa-search{
    position: absolute;
    top:15px;
    left: 20px;
    color: #9ca3af;
}

.form-input{
  height: 40px;
  width:343px;
  text-indent: 33px;
  border-radius: 12px;
  border:1px;
}

.form-input:focus{
  box-shadow: none;
  border:none;
}
