body {
  margin: 0;
  font-family:  'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Roboto', sans-serif;
  outline: none !important;
}

.page-heading{
  color: #000C47;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0rem !important;
}

.center-block{
  display: flex;
  justify-content: center;
  align-items: center;
}